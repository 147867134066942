import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
  <li>
    <a href="https://phoenixphrenzy.com">
      <i className="fas fa-globe-americas fa-lg"></i>
      View site
    </a>
  </li>
    </ul>
    <p>{`At DockYard I had the opportunity to work on the exciting launch of the Phoenix Phrenzy competition. We built a web application with the ground breaking LiveView platform in Phoenix and hosted a competition for others to showcase their work.`}</p>
    <p>{`My work focused on HTML and CSS development based on design mockups.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/dy-ph2-home.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/work/dy-ph2-profile.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/work/dy-ph2-footer.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      